import * as React from "react";
import Layout from "../../layout";
import Contact from "../../components/Contact/ContactPage/Contact";

const ContactPage = () => {
  return (
    <Layout
      title={"Contact"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
    >
      <Contact />
    </Layout>
  );
};

export default ContactPage;
