import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import axios from "axios";
import { toast } from "react-toastify";
import ReCapthchaV2 from "react-google-recaptcha";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactPgGlobalData {
      prismicGlobalData {
        dataRaw
      }
    }
  `);

  const globalData = data.prismicGlobalData.dataRaw;

  const [progress, setProgress] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [message, setMessage] = useState("");
  const [consent, setConsent] = useState(false);
  const [reCap, setReCap] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();
    setProgress(true);
    await axios.post("/api/sendmail", {
      userEmail: email,
      subject: `[corsnet.lk] Contact Form Submission`,
      fields: [
        {
          key: "First Name",
          value: firstName,
        },
        {
          key: "Last Name",
          value: lastName,
        },
        {
          key: "Email",
          value: email,
        },
        {
          key: "Department",
          value: department,
        },
        {
          key: "Message",
          value: message,
        },
      ],
    });

    toast.success(
      "We got your message. One of our agents will get back to you soon."
    );
    setProgress(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
    setDepartment("");
    setConsent(false);
  };

  const onChange = (e) => {
    setReCap(true);
  };

  return (
    <section className="contact-page wrapper bg-light">
      <div className="container py-14 py-md-16">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="row gy-10 gx-lg-8 gx-xl-12">
              <div className="col-lg-8">
                <form
                  className="contact-form needs-validation"
                  method="post"
                  action="#"
                  onSubmit={handleForm}
                >
                  <div className="messages" />
                  <div className="row gx-4">
                    <div className="col-md-6">
                      <div className="form-floating mb-4">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Jane"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <label htmlFor="form_name">First Name *</label>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter your first name.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-4">
                        <input
                          id="form_lastname"
                          type="text"
                          name="surname"
                          className="form-control"
                          placeholder="Doe"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <label htmlFor="form_lastname">Last Name *</label>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter your last name.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-4">
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="jane.doe@example.com"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor="form_email">Email *</label>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please provide a valid email address.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          id="form-select"
                          name="department"
                          required
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                        >
                          <option value="">Select a department</option>
                          <option value="Sales">Sales</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Customer Support">
                            Customer Support
                          </option>
                        </select>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please select a department.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating mb-4">
                        <textarea
                          id="form_message"
                          name="message"
                          className="form-control"
                          placeholder="Your message"
                          style={{ minHeight: "150px" }}
                          required
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <label htmlFor="form_message">Message *</label>
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter your messsage.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="invalidCheck"
                          required
                          checked={consent}
                          onChange={(e) => {
                            setConsent(e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="invalidCheck"
                        >
                          I agree to{" "}
                          <Link to="/privacy-policy" className="hover">
                            Privacy Policy
                          </Link>
                          .
                        </label>
                        <div className="invalid-feedback">
                          You must agree before submitting.
                        </div>
                      </div>
                    </div>
                    <ReCapthchaV2
                      className="mb-4 rounded"
                      sitekey="6Le7NogfAAAAAAEpO5of33E3sZ9sl3R8dgHMK9j8"
                      onChange={(e) => onChange(e)}
                    />
                    <div className="col-12">
                      <input
                        type="submit"
                        className="btn btn-primary rounded-pill btn-send mb-3"
                        value="Send message"
                        disabled={
                          !email ||
                          !consent ||
                          !firstName ||
                          !lastName ||
                          !message ||
                          !reCap ||
                          progress
                        }
                      />
                      <p className="text-muted">
                        Fields marked with <strong>*</strong> are required
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 details">
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-location-pin-alt"></i>{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">Address</h5>
                    <a
                      href="https://goo.gl/maps/vTtyuYxezdZyk5SY6"
                      target="_blank"
                      className="text-body"
                      rel={"noreferrer"}
                    >
                      {globalData.address}
                    </a>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-phone-volume"></i>{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">Phone</h5>
                    <p className="mb-0">
                      <a
                        href={`tel:${globalData.phone_number}`}
                        className="text-body"
                      >
                        {globalData.phone_number}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-envelope"></i>{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">E-mail</h5>
                    <p className="mb-0">
                      <a
                        href={`mailto:${globalData.email}`}
                        className="text-body"
                      >
                        {globalData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
